import {colors} from "@c10h/colors";
import React from "react";
import {v5Pages} from "src/components/_common/_constants";
import {ImageItemList} from "src/components/_common/ImageItemList";
import {Btn} from "src/components/v5/V5Comp";
import Image from "next/image";
import wordCircle from "public/static/img/v5/home/word-circle.jpg";
import homeIllustratedList1 from "public/static/img/v5/home/illustrated-list/01.jpg";
import homeIllustratedList2 from "public/static/img/v5/home/illustrated-list/02.jpg";
import homeIllustratedList3 from "public/static/img/v5/home/illustrated-list/03.jpg";
import homeIllustratedList4 from "public/static/img/v5/home/illustrated-list/04.jpg";
import ContentArea from "../../composition/ContentArea";
import Section from "../../composition/Section";
import {Breakpoint} from "src/constants/breakpoint";
import {Trans} from "@i18n";
import {TranslateFn} from "@i18n";

type Props = {
  t: TranslateFn;
};

const wordCircleStyle = {right: 0, top: "-25%"};

export const ImageGrid: React.FC<Props> = ({t}) => {
  const bookingUrl = v5Pages.locations;

  const sizes = `(max-width: ${Breakpoint.SM}px) 100px, 190px`;
  const imageClassName = "h-[100px] w-[100px] sm:h-[190px] sm:w-[190px]";
  const itemList = [
    {
      header: t("Book same day appointments"),
      content: t("Primary, urgent, and virtual care visits on YOUR schedule."),
      image: {
        src: homeIllustratedList1,
        sizes,
        className: imageClassName,
      },
    },
    {
      header: t("Chat with your health team"),
      content: t("Get treated for a range of symptoms & conditions right from your smartphone."),
      image: {
        src: homeIllustratedList2,
        sizes,
        className: imageClassName,
      },
    },
    {
      header: t("Prescriptions at your doorstep"),
      content: t(
        "Get your medications sent straight to your chosen pharmacy or to your door—whatever works for you.",
      ),
      image: {
        src: homeIllustratedList3,
        sizes,
        className: imageClassName,
      },
    },
    {
      header: t("All your health in one place"),
      content: t("Access your medical charts, complete records, and test results immediately."),
      image: {
        src: homeIllustratedList4,
        sizes,
        className: imageClassName,
      },
    },
  ];

  return (
    <Section id="learn-more" className="pv40 pv16-md">
      <ContentArea className="flex-col">
        <h2 className="font-csb lh12 fs48 fs24-md lh14-md mb16 mb8-md dif">
          <Trans t={t}>
            <span>
              A friendlier healthcare experience,{" "}
              <strong className="db di-md darkerYellow">
                without{" "}
                <span className="pos-r zIndex1">
                  the membership fees.{" "}
                  <Image
                    alt=""
                    style={wordCircleStyle}
                    src={wordCircle}
                    className="op5 pos-a maxw20-md dn-md zIndex-1"
                  />
                </span>
              </strong>
            </span>
          </Trans>
        </h2>
        <Btn
          href={bookingUrl}
          bg={colors.gray800}
          className="mb24 mb8-md fs14-f db-f event-pdwXAy focus-bsDarkBlue3"
        >
          {t("Book an Appointment")}
        </Btn>
        <ImageItemList rows={2} items={itemList} t={t} />
      </ContentArea>
    </Section>
  );
};
